import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unsubscribe-news-letter',
  templateUrl: './unsubscribe-news-letter.component.html',
  styleUrls: ['./unsubscribe-news-letter.component.scss']
})
export class UnsubscribeNewsLetterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
